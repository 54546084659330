const ROOT = '/jmicro/JMICRO';
export default  {

  ROOT,
  MNG : 'mng',
  LOG2LEVEL : {2:'LOG_DEBUG', 5:'LOG_ERROR', 6:'LOG_FINAL', 3:'LOG_INFO', 0:'LOG_NO',
  1: 'LOG_TRANCE', 4:'LOG_WARN'},

  RES_STATUS: {"1":"Uploading","2":"Ready","3":"Enable","4":"Error","5":"WAITING","6":"Download"},
  DEP_STATUS:{'1':'Draft','2':'Enable','3':"Check"},
  ROUTER_ROOT : ROOT + '/routeRules',
  CONFIG_ROOT : ROOT,
  AGENT_ROOT : ROOT + '/choreography/agents',
  INSTANCE_ROOT : ROOT + '/choreography/instances',
  DEP_ROOT : ROOT + '/choreography/deployments',

  RULE_ID: 'cn.jmicro.api.route.RouteRule',

  IOT : {
	  DEV_ROLE: {
			MASTER : 1,//主设备，可以通过主设备控制从设备
		  	SLAVE : 0,//从设备，可通过主设备控制从设备
		  	STANDALONE : 2,//独立设备，独立存在设备，不可以加入主设备，也不可以控制其他设备
			ALL : 3,
	  },
	  
	  EVENT_CODES : {
	  	ASR : 1, //语音命令
	  	IR : 2, //红外遥控
	  	KEY : 3, //物理按键
	  	OTHER : 4 ,//其他
		MQ : 5, //MQ传感器事件
	  },
	  
	  JM_EVENT_CODE_LABELS:["语音识别","红外遥控","物理按键","其他","MQ传感器事件"],
	  
	  JM_EVENT_EXE : {
		SERVER : 1,//服务端执行
		MASTER_DEV : 2, //主设备执行
		SLAVE_DEV : 3, //从设备执行
		THIRD : 4   //由三方系统执行
	  },
	  
	   JM_EVENT_EXE_LABELS:["服务端","主设备","从设备","由三方系统"],
	   
	   JM_OP_SRC : {
			PRODUCT : 1,
			DEVICE : 2,
			
	   }, //USER : 3
	   //1:产品， 2： 设备,  3:用户
		JM_OP_SRC_LABELS:["产品","设备","用户"],
	   

	  getEventCodeLabel : function(eventCode) {
	  	return this.JM_EVENT_CODE_LABELS[parseInt(eventCode)-1]
	  },
	  
	  getDevRoleLabel : function(deviceRole) {
	  	return deviceRole==this.DEV_ROLE.MASTER ? '主设备':
	  	deviceRole==this.DEV_ROLE.SLAVE ? '从设备':'独立设备'
	  },
	  
	  isDevMaster : function(deviceRole) {
	  	return deviceRole==this.DEV_ROLE.MASTER
	  },
	  
	  isDevSlave : function(deviceRole) {
	  	return deviceRole==this.DEV_ROLE.SLAVE
	  },
	  
	  isDevStandalone : function(deviceRole) {
	  	return deviceRole==this.DEV_ROLE.STANDALONE
	  },
  }
	
}
