(function (root, factory, undef) {
    if (typeof exports === 'object') {
        // CommonJS
        module.exports = exports = factory(
            require('./core'),
            require('./x64-core'),
            require('./lib-typedarrays'),
            require('./enc-utf16'),
            require('./enc-base64'),
            require('./enc-base64url'),
            require('./md5'),
            require('./sha1'),
            require('./sha256'),
            require('./sha224'),
            require('./sha512'),
            require('./sha384'),
            require('./sha3'),
            require('./ripemd160'),
            require('./hmac'),
            require('./pbkdf2'),
            require('./evpkdf'),
            require('./cipher-core'),
            require('./mode-cfb'),
            require('./mode-ctr'),
            require('./mode-ctr-gladman'),
            require('./mode-ofb'),
            require('./mode-ecb'),
            require('./pad-ansix923'),
            require('./pad-iso10126'),
            require('./pad-iso97971'),
            require('./pad-zeropadding'),
            require('./pad-nopadding'),
            require('./format-hex'),
            require('./aes'),
            require('./tripledes'),
            require('./rc4'),
            require('./rabbit'),
            require('./rabbit-legacy')
        );
    } else {
        if (typeof define === 'function' && define.amd) {
            // AMD
            define([
                './core',
                './x64-core',
                './lib-typedarrays',
                './enc-utf16',
                './enc-base64',
                './enc-base64url',
                './md5',
                './sha1',
                './sha256',
                './sha224',
                './sha512',
                './sha384',
                './sha3',
                './ripemd160',
                './hmac',
                './pbkdf2',
                './evpkdf',
                './cipher-core',
                './mode-cfb',
                './mode-ctr',
                './mode-ctr-gladman',
                './mode-ofb',
                './mode-ecb',
                './pad-ansix923',
                './pad-iso10126',
                './pad-iso97971',
                './pad-zeropadding',
                './pad-nopadding',
                './format-hex',
                './aes',
                './tripledes',
                './rc4',
                './rabbit',
                './rabbit-legacy'
            ], factory);
        } else {
            // Global (browser)
            root.CryptoJS = factory(root.CryptoJS);
        }
    }
})(this, function (CryptoJS) {
    return CryptoJS;
});
