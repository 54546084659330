(function (root, factory, undef) {
    if (typeof exports === 'object') {
        // CommonJS
        module.exports = exports = factory(require('./core'), require('./cipher-core'));
    } else {
        if (typeof define === 'function' && define.amd) {
            // AMD
            define(['./core', './cipher-core'], factory);
        } else {
            // Global (browser)
            factory(root.CryptoJS);
        }
    }
})(this, function (CryptoJS) {
    /**
     * Zero padding strategy.
     */
    CryptoJS.pad.ZeroPadding = {
        pad: function (data, blockSize) {
            // Shortcut
            var blockSizeBytes = blockSize * 4; // Pad

            data.clamp();
            data.sigBytes += blockSizeBytes - (data.sigBytes % blockSizeBytes || blockSizeBytes);
        },
        unpad: function (data) {
            // Shortcut
            var dataWords = data.words; // Unpad

            var i = data.sigBytes - 1;

            for (var i = data.sigBytes - 1; i >= 0; i--) {
                if ((dataWords[i >>> 2] >>> (24 - (i % 4) * 8)) & 255) {
                    data.sigBytes = i + 1;
                    break;
                }
            }
        }
    };
    return CryptoJS.pad.ZeroPadding;
});
