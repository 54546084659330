export default {
	MU_WITH_MSSEC : 1*60*1000,
	VER_DATA_KEY:'_jverDataKey',
    MessageCls: 'cn.jmicro.api.net.MessageJRso',
    IRequestCls: 'cn.jmicro.api.server.IRequest',
    ServiceStatisCls: 'cn.jmicro.api.monitor.ServiceStatisJRso',
    ISessionCls: 'cn.jmicro.api.net.ISession',
    Integer: 3,
    LOng: 4,
    String: 5,
    DEFAULT_NAMESPACE: 'defaultNamespace',
    DEFAULT_VERSION: '0.0.1',
    MNG: 'mng',
    NS_MNG: 'mng',
    NS_SECURITY: 'security',
    NS_API_GATEWAY: 'apigateway',
    NS_RESPOSITORY: 'repository',
    NS_PUBSUB_SERVER: 'pubSubServer',
	DEV_TOPIC_PREFIX : "/__act/dev/",
    // debug level
    INFO: 'INFO',
    DEBUG: 'DEBUG',
    ERROR: 'ERROR',
    FINAL: 'FINAL',
    DEFAULT: 'DEFAULT',
    IE: 'ie',
    IE6: 'ie6',
    IE7: 'ie7',
    IE8: 'ie8',
    IE9: 'ie9',
    IE10: 'ie10',
    IE11: 'ie11',
    chrome: 'chrome',
    firefox: 'firefox',
    safari: 'safari',
    opera: 'opera'
};
